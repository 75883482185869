#dailyReportPreviewContainerRotate {
    font-family:Arial, Helvetica, sans-serif;
    background-color: #fff;
    position: absolute;
    height: 1024px;
    width: 100%;
    margin-left: 0;
    margin-top: 0;
    /* transform: rotate(-90deg); */
    z-index: 10000;
}

#dailyReportPreviewContainer {
    font-family:Arial, Helvetica, sans-serif;
    background-color: #fff;
    width: 768px;
    margin: 0 auto;
    /* box-shadow: 3px 3px 10px rgba(0,0,0,.5) */
}

input.change-textfield {
    border: 0 none !important;
    padding: 0 !important;
    margin: 0 !important;
    font-size: 11px !important;
}

/* #dailyReportPreviewContainer #job-area *,
#dailyReportPreviewContainer #description-area *,
#dailyReportPreviewContainer #billTo-area * {
    font-size: 14px !important;
} */

#dailyReportPreviewContainer #preview {
    font-family: Arial, Helvetica, serif;
    background-color: #fff;
    margin: 20px;

}

#dailyReportPreviewContainer input {
    min-width: initial;
    min-height: initial
}

#dailyReportPreviewContainer #html {
    /* font-family: "Times New Roman", serif !important; */
    margin: 0px;
    line-height: normal !important;
}

.signatureImagePreview {
    width: 180px;
    height: 60px;
}

#dailyReportPreviewContainer #previewContainer table {
    border: 0 none;
}

#dailyReportPreviewContainer #previewContainer h1 {
    font-size: 30px;
    text-align: right;
    margin: 0;
}

#dailyReportPreviewContainer #previewContainer p {
    font-size: 14px;
    margin-top: 7px;
}

#dailyReportPreviewContainer #previewContainer td {
    vertical-align: bottom;
}

#dailyReportPreviewContainer #previewContainer th {
    text-transform: uppercase;
    font-weight: normal;
    text-align: center;
}

#dailyReportPreviewContainer #previewContainer input {
    border: 0 none;
    width: 95%;
    height: 100%;
    font-size: 10px !important;
    background-color: transparent;
    background-image: none !important;
}

#dailyReportPreviewContainer #previewContainer form {
    display: inline-block;
    width: 100%;
    height: 100%;
}

#dailyReportPreviewContainer #previewContainer .page-container {
    padding: 0 25px;
    background-color: #fff;
    width: 670px;
    margin: 0 auto;
    /*box-shadow: 3px 3px 10px rgba(0,0,0,.5);*/
}

#dailyReportPreviewContainer #previewContainer .page {
    width: 670px;
    margin: 0 auto;
}

@media print {
    #dailyReportPreviewContainer .page-container {
        padding: 0 !important;
        background-color: transparent !important;
        width: auto !important;
        margin: auto !important;
        box-shadow: none !important;
    }

    #dailyReportPreviewContainer .page {
        width: auto;
        margin: 0 !important;
    }

    .closeButton {
        display: none;
    }

    [data-lastpass-root] {
        display: none !important;
    }

    .page {
        page-break-after: always;
        /* Old CSS2 syntax */
        break-after: page;
        /* Newer syntax */
    }

    .page:last-child {
        page-break-after: auto;
        /* Prevents a break after the last element */
        break-after: auto;
        /* Ensures no blank page at the end */
    }

    .toastPanel {
        display: none;
    }

    /* body,
    html {
        background-color: transparent;
        margin: 0;
    } */
}

#dailyReportPreviewContainer #header {
    width: 100%;
}

#dailyReportPreviewContainer #logoArea {
    width: 50%;
    text-align: left;
    font-size: 9px;
}

#dailyReportPreviewContainer #logo {
    height: 80px;
}

#dailyReportPreviewContainer #titleArea {
    width: 77%;
    text-align: right;
    white-space: nowrap;
    float: right !important;
}

#dailyReportPreviewContainer #job-area {
    width: 100%;
    margin: 8px auto;
    font-size: 14px;
}

#dailyReportPreviewContainer #JobNumber-label {
    width: 5%;
    text-align: left;
}

#dailyReportPreviewContainer #JobNumber-field {
    width: 15%;
}

#dailyReportPreviewContainer #JobName-label {
    width: 15%;
    text-align: right;
}

#dailyReportPreviewContainer #JobName-field {
    width: 40%;
}

#dailyReportPreviewContainer #Date-label {
    width: 7%;
    text-align: right;
}

#dailyReportPreviewContainer #Date-field {
    width: 13%;
}

#dailyReportPreviewContainer #description-area {
    width: 100%;
    margin: 8px auto;
    font-size: 14px;
}

#dailyReportPreviewContainer #DescriptionOfWork-label {
    width: 20%;
    text-align: left;
}

#dailyReportPreviewContainer #DescriptionOfWork-field {
    width: 57%;
}

#dailyReportPreviewContainer #County-label {
    width: 7%;
    text-align: right;
}

#dailyReportPreviewContainer #County-field {
    width: 13%;
}

#dailyReportPreviewContainer #billTo-area {
    width: 100%;
    margin: 8px auto;
    font-size: 14px;
}

#dailyReportPreviewContainer #BillTo-label {
    width: 7%;
    text-align: left;
}

#dailyReportPreviewContainer #BillTo-field {
    width: 65%;
}

#dailyReportPreviewContainer #RFCO-label {
    width: 11%;
    text-align: right;
}

#dailyReportPreviewContainer #RFCO-field {
    width: 17%;
}

#dailyReportPreviewContainer #labor,
#dailyReportPreviewContainer #equipmentRentals,
#dailyReportPreviewContainer #hauling,
#dailyReportPreviewContainer #suppliesAndMaterials,
#dailyReportPreviewContainer #totals {
    border-right: 1px solid #000;
    border-bottom: 3px solid #000;
}

#dailyReportPreviewContainer #labor td,
#dailyReportPreviewContainer #equipmentRentals td,
#dailyReportPreviewContainer #hauling td,
#dailyReportPreviewContainer #suppliesAndMaterials td,
#dailyReportPreviewContainer #totals td {
    border-left: 1px solid #000;
    border-top: 1px solid #000;
    font-size: 10px;
}

#dailyReportPreviewContainer #labor th,
#dailyReportPreviewContainer #equipmentRentals th,
#dailyReportPreviewContainer #hauling th,
#dailyReportPreviewContainer #suppliesAndMaterials th {
    border-left: 1px solid #000;
    border-top: 1px solid #000;
    font-size: 11px;
}

#dailyReportPreviewContainer #labor {
    width: 100%;
}

#dailyReportPreviewContainer #LaborCostCodes {
    width: 10%;
}

#dailyReportPreviewContainer #LaborName {
    width: 14%;
}

#dailyReportPreviewContainer #LaborTime {
    width: 8%;
}

#dailyReportPreviewContainer #LaborEquipmentOperated {
    width: 11%;
}

#dailyReportPreviewContainer #LaborEquipmentNumber {
    width: 12%;
}

#dailyReportPreviewContainer #LaborReg {
    width: 5%;
}

#dailyReportPreviewContainer #LaborRateReg {
    width: 6%;
}

#dailyReportPreviewContainer #LaborOT {
    width: 5%;
}

#dailyReportPreviewContainer #LaborRateOT {
    width: 6%;
}

#dailyReportPreviewContainer #LaborDT {
    width: 5%;
}

#dailyReportPreviewContainer #LaborRateDT {
    width: 6%;
}

#dailyReportPreviewContainer #LaborAmount {
    width: 12%;
}

#dailyReportPreviewContainer #equipmentRentals {
    width: 100%;
}

#dailyReportPreviewContainer #EquipmentRentalsCostCodes {
    width: 10%;
}

#dailyReportPreviewContainer #EquipmentRentalsCo {
    width: 25%;
}

#dailyReportPreviewContainer #EquipmentRentalsType {
    width: 12%;
}

#dailyReportPreviewContainer #EquipmentRentalsSerialEquipmentNumber {
    width: 17%;
}

#dailyReportPreviewContainer #EquipmentRentalsDay {
    width: 12%;
}

#dailyReportPreviewContainer #EquipmentRentalsRate {
    width: 12%;
}

#dailyReportPreviewContainer #EquipmentRentalsAmount {
    width: 12%;
}

#dailyReportPreviewContainer #hauling {
    width: 100%;
}

#dailyReportPreviewContainer #HaulingCostCodes {
    width: 10%;
}

#dailyReportPreviewContainer #HaulingCo {
    width: 25%;
}

#dailyReportPreviewContainer #HaulingType {
    width: 21%;
}

#dailyReportPreviewContainer #HaulingLoads {
    width: 8%;
}

#dailyReportPreviewContainer #HaulingTons {
    width: 8%;
}

#dailyReportPreviewContainer #HaulingHours {
    width: 8%;
}

#dailyReportPreviewContainer #HaulingRate {
    width: 8%;
}

#dailyReportPreviewContainer #HaulingAmount {
    width: 12%;
}

#dailyReportPreviewContainer #suppliesAndMaterials {
    width: 100%;
}

#dailyReportPreviewContainer #SuppliesAndMaterialsCostCodes {
    width: 10%;
}

#dailyReportPreviewContainer #SuppliesAndMaterialsName {
    width: 48%;
}

#dailyReportPreviewContainer #SuppliesAndMaterialsQuantity {
    width: 14%;
}

#dailyReportPreviewContainer #SuppliesAndMaterialsUnit {
    width: 8%;
}

#dailyReportPreviewContainer #SuppliesAndMaterialsPrice {
    width: 8%;
}

#dailyReportPreviewContainer #SuppliesAndMaterialsAmount {
    width: 12%;
}

#dailyReportPreviewContainer #totals {
    width: 100%;
    height: 35px;
}

#dailyReportPreviewContainer #totals td {
    vertical-align: middle;
}

#dailyReportPreviewContainer #remarks {
    width: 10%;
    text-align: center;
}

#dailyReportPreviewContainer #remarks-field {
    width: 70%;
}

#dailyReportPreviewContainer #total {
    width: 8%;
    text-align: center;
}

#dailyReportPreviewContainer #total-field {
    width: 12%;
}

#dailyReportPreviewContainer #signatures {
    width: 85%;
    margin: 0 auto;
}

#dailyReportPreviewContainer #authorizedBySignature-field,
#dailyReportPreviewContainer #foremanSignature-field {
    width: 40%;
}

#dailyReportPreviewContainer #signatures .spacer {
    width: 20%;
}

#dailyReportPreviewContainer .uppercase * {
    text-transform: uppercase;
}

#dailyReportPreviewContainer .textfield {
    border-bottom: 1px solid #000;
    margin-right: 5%;
    font-family: Arial, Helvetica, sans-serif;
}

#dailyReportPreviewContainer .textfield-noborder {
    margin-right: 5%;
    font-family: Arial, Helvetica, sans-serif;
}

#dailyReportPreviewContainer .signature {
    height: 30px;
}

#dailyReportPreviewContainer .center * {
    text-align: center;
}

#dailyReportPreviewContainer .right * {
    text-align: right;
}

#dailyReportPreviewContainer .bold {
    text-transform: uppercase;
    font-weight: bold !important;
    font-size: 1.5;
}