#safetyAppPreviewContainer {
    background-color: #fff;
    /* position: absolute; */
    /* height: 1024px; */
    width: 768px;
    /* margin-left: 118px;
    margin-top: -159px; */
    /* transform: rotate(-90deg); */
    z-index: 10000;
    margin: 0 auto;
}

#safetyAppPreviewContainer table {
    border-collapse: inherit !important;
}

#safetyAppPreviewContainer #job-area *,
#safetyAppPreviewContainer#description-area *,
#safetyAppPreviewContainer #billTo-area * {
    font-size: 14px;
}

#safetyAppPreviewContainer #preview {
    font-family: Arial, Helvetica, serif !important;
    background-color: #fff;
    margin: 20px;

}

#safetyAppPreviewContainer #html {
    font-family: "Times New Roman", serif !important;
    background-color: #888;
    margin: 20px;
    line-height: normal !important;
}

#safetyAppPreviewContainer .page-container {
    padding: 0 25px 25px !important;
    background-color: #fff;
    width: 670px;
    margin: 0 auto;
    box-shadow: 3px 3px 10px rgba(0, 0, 0, .5);
}
/* 
#safetyAppPreviewContainer h1 {
    
    font-size: 30px;
    text-decoration: underline;
    text-align: center;
}

#safetyAppPreviewContainer p {
    font-size: 14px;
    margin-top: 7px;
}

#safetyAppPreviewContainer td {
    vertical-align: bottom;
}



#safetyAppPreviewContainer .page {
    width: 670px;
    margin: 0 auto;
}

@media print {
    #safetyAppPreviewContainer .page-container {
        padding: 0;
        background-color: transparent;
        width: auto;
        margin: auto;
        box-shadow: none;
    }

    #safetyAppPreviewContainer .page {
        width: auto;
        margin: auto;
    }

    #safetyAppPreviewContainer body,
    #safetyAppPreviewContainer html {
        background-color: transparent;
        margin: 0;
    }
}

#safetyAppPreviewContainer #header {
    font-family: Arial, Helvetica, sans-serif;
}

#safetyAppPreviewContainer #header td {
    vertical-align: middle;
}

#safetyAppPreviewContainer #mailingAddress {
    width: 25%;
    font-size: 9px;
    font-family: Arial, Helvetica, sans-serif !important;
}

#safetyAppPreviewContainer #mailingAddress p {
    font-size: 10px;
    font-family: Arial, Helvetica, sans-serif !important;
}

#safetyAppPreviewContainer #logoArea {
    width: 50%;
    text-align: center;
    font-size: 9px;
}

#safetyAppPreviewContainer #logo {
    height: 80px;
}

#safetyAppPreviewContainer #phoneArea {
    width: 25%;
    text-align: right;
    font-size: 10px;
}

#safetyAppPreviewContainer #phoneArea p {
    font-size: 10px;
}

#safetyAppPreviewContainer #job-area {
    width: 90%;
    margin: 0 auto;
    font-size: 18px;
}

#safetyAppPreviewContainer #Date-label {
    width: 10%;
    text-align: right;
    padding-right: 2%;
}

#safetyAppPreviewContainer #Date-field {
    width: 30%;
}

#safetyAppPreviewContainer #JobName-label {
    width: 15%;
    text-align: right;
    padding-right: 2%;
}

#safetyAppPreviewContainer #JobName-field {
    width: 30%;
}

#safetyAppPreviewContainer #a-questions {
    width: 50%;
    margin-left: 10%;
    font-size: 14px;
    margin-top: 10px;
}

#safetyAppPreviewContainer #a-questions-title {
    width: 60%;
}

#safetyAppPreviewContainer #a-questions-yes {
    width: 18%;
    text-transform: uppercase;
}

#safetyAppPreviewContainer #a-questions-no {
    width: 18%;
    text-transform: uppercase;
}

#safetyAppPreviewContainer #a-questions .textfield {
    margin-right: 5%;
}

#safetyAppPreviewContainer #foreman-signature-area {
    width: 50%;
    margin-left: 50%;
    font-size: 14px;
}

#safetyAppPreviewContainer #ForemansSignature-label {
    width: 50%;
    text-align: right;
}

#safetyAppPreviewContainer #ForemansSignature-field {
    width: 50%;
}

#safetyAppPreviewContainer #daily-comments-area {
    width: 100%;
    font-size: 14px;
}

#safetyAppPreviewContainer #DailyComments-label {
    width: 25%;
    text-align: left;
    vertical-align: top;
}

#safetyAppPreviewContainer #DailyComments-field {
    width: 75%;
    padding-left: 5%;
    text-align: left;
    height: 40px;
    vertical-align: top;
}

#safetyAppPreviewContainer #b-questions {
    width: 90%;
    margin-left: 10%;
    font-size: 14px;
    margin-top: 10px;
}

#safetyAppPreviewContainer #b-questions-title {
    width: 75%;
}

#safetyAppPreviewContainer #b-questions-yes {
    width: 10%;
    text-transform: uppercase;
}

#safetyAppPreviewContainer #b-questions-no {
    width: 10%;
    text-transform: uppercase;
}

#safetyAppPreviewContainer #b-questions .textfield {
    margin-right: 5%;
}

#safetyAppPreviewContainer #employee-signature-area {
    width: 100%;
}

#safetyAppPreviewContainer #employee-signature-area td {
    width: 33%;
    height: 30px;
}

#safetyAppPreviewContainer #equipment-repair-area {
    width: 100%;
    font-size: 14px;
}

#safetyAppPreviewContainer #EquipmentRepair-label {
    width: 25%;
    text-align: left;
    vertical-align: top;
}

#safetyAppPreviewContainer #EquipmentRepair-field {
    width: 75%;
    padding-left: 5%;
    text-align: left;
    height: 40px;
    vertical-align: top;
}

#safetyAppPreviewContainer .uppercase {
    text-transform: uppercase;
}

#safetyAppPreviewContainer .textfield {
    border-bottom: 1px solid #000;
    margin-right: 5%;
    font-family: Arial, Helvetica, sans-serif;
}

#safetyAppPreviewContainer .textfield-noborder {
    margin-right: 5%;
    font-family: Arial, Helvetica, sans-serif;
}*/

#safetyAppPreviewContainer .signature {
    height: 30px;
    max-width: 90px;
}

#safetyAppPreviewContainer .yesno {
    text-align: center;
} 