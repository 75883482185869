@font-face {
     font-family: 'Arial Narrow';
     src: url('./fonts/arial_narrow-webfont.woff2') format('woff2'),
          url('./fonts/arial_narrow-webfont.woff') format('woff');
     font-weight: normal;
     font-style: normal;
}

@font-face {
     font-family: 'Arial Narrow';
     src: url('./fonts/arial_narrow_bold-webfont.woff2') format('woff2'),
          url('./fonts/arial_narrow_bold-webfont.woff') format('woff');
     font-weight: bold;
     font-style: normal;
}